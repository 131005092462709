/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: '/dashboards/counter'
    },
    // {
    //     id: 'payments',
    //     title: 'Payments',
    //     type: 'basic',
    //     icon: 'heroicons_outline:currency-dollar',
    //     link: '/payments'
    // },
    // {
    //     id: 'clients',
    //     title: 'Clients',
    //     type: 'basic',
    //     icon: 'heroicons_outline:user-group',
    //     link: '/clients'
    // },
    // {
    //     id: 'agents',
    //     title: 'Agents',
    //     type: 'basic',
    //     icon: 'heroicons_outline:user',
    //     link: '/agents'
    // },
    // {
    //     id: 'projects',
    //     title: 'Projects',
    //     type: 'basic',
    //     icon: 'heroicons_outline:home',
    //     link: '/projects'
    // },
    // {
    //     id: 'bank-accounts',
    //     title: 'Bank Accounts',
    //     type: 'basic',
    //     icon: 'heroicons_outline:library',
    //     link: '/bank-accounts'
    // }
    // ,
    {
        id: 'dispatches',
        title: 'Dispatches',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/dispatches'
    },
    {
        id: 'rental-summary',
        title: 'Rental Summary',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/rental-summary'
    },
    {
        id: 'employee',
        title: 'Employees',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/employees'
    }
    ,
    {
        id: 'spare-parts',
        title: 'Spare Parts',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/spare-parts'
    },
    {
        id: 'taxi',
        title: 'Taxi',
        type: 'basic',
        icon: 'heroicons_outline:truck',
        link: '/taxi'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: '/reports'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
